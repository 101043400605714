import { useState } from "react";
import ActivityDetail from "../ActivityDetail.jsx";
import { Grass, Help } from "@mui/icons-material";
import { Box, Divider, Grid, Link, MenuItem, Tooltip, Typography } from "@mui/material";
import TextField from "../common/TextField.jsx";
import Autocomplete from "../common/Autocomplete.jsx";
import ResidueManagementOperations from "./residue_management_operations.json";
import Select from "../common/Select.jsx";

export default function ResidueManagement() {
    const [values, setValues] = useState({ operation: "Burn residue, moderate intensity" });
    const handleSubmit = data => {
        setValues(data);
    };

    ResidueManagementOperations.sort((a, b) => a.name.localeCompare(b.name));

    const [operationDescription, setOperationDescription] = useState("");
    const [operationStir, setOperationStir] = useState("");
    const handleOperation = event => {
        const operationObject = filteredOperations.find(obj => {
            return obj.name == event.target.value;
        });
        if (operationObject) {
            setOperationDescription(operationObject.description);
            setOperationStir(operationObject.stir);
        }
    };

    const filteredOperations = ResidueManagementOperations.filter(operation => operation.most_common);

    return (
        <ActivityDetail
            values={values}
            icon={<Grass />}
            type="Residue Management"
            summary={
                <>
                    <Grid item sm={4} xs={12}>
                        <Typography style={{ fontSize: "14px" }}>EQUIPMENT / OPERATION TYPE</Typography>
                        <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>{values.operation}</Typography>
                    </Grid>
                </>
            }
            onSubmit={handleSubmit}>
            <Grid container paddingTop={2} spacing={1}>
                <Grid item md={7} sm={7} xs={12}>
                    <Typography>Activity Date</Typography>
                    <TextField
                        field="date"
                        type="date"
                        size="small"
                        sx={{ width: "180px" }}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Typography>Residue Management Operation</Typography>
                    <Select size="small" field="operation" sx={{ width: "380px" }} onChange={handleOperation} fullWidth>
                        {filteredOperations.map(operation => (
                            <MenuItem key={operation.name} value={operation.name}>
                                {operation.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <Divider variant="middle" component="li" sx={{ listStyleType: "none" }} />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <Typography>Operation Description</Typography>
                    <Box sx={{ backgroundColor: "grey.300", borderRadius: 1, padding: 1 }}>
                        <Typography>{operationDescription}</Typography>
                    </Box>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <Typography>
                        Still Tillage Intensity Rating{" "}
                        <Tooltip
                            sx={{ verticalAlign: "middle" }}
                            title={
                                <Typography sx={{ color: "white", fontSize: "14px" }}>
                                    Soil Tillage Intensity Rating (STIR) Soil Tillage Intensity Rating is a numerical
                                    value calculated using RUSLE2. It is based on factors determined by crop management
                                    decisions being implemented for a particular field. Lower numbers indicate less
                                    overall disturbance to the soil layer.{" "}
                                    <Link
                                        target="_blank"
                                        href="https://www.nrcs.usda.gov/sites/default/files/2023-01/Soil-Tillage-Intensity-Rating-Fact-Sheet3-27-2020.pdf">
                                        https://www.nrcs.usda.gov/sites/default/files/2023-01/Soil-Tillage-Intensity-Rating-Fact-Sheet3-27-2020.pdf
                                    </Link>
                                </Typography>
                            }>
                            <Help fontSize="small" />
                        </Tooltip>
                    </Typography>
                    <Box sx={{ backgroundColor: "grey.300", borderRadius: 1, width: "50px", padding: 1 }}>
                        <Typography>{operationStir}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </ActivityDetail>
    );
}

import { Select as MuiSelect } from "@mui/material";
import { FormField } from "react-form";

function Select({ fieldApi, onChange, ...rest }) {
    const { getValue, setValue } = fieldApi;
    const handleChange = (event, value) => {
        if (onChange) {
            onChange(event, value);
        }
        setValue(event.target.value);
    };
    return <MuiSelect value={getValue()} onChange={handleChange} {...rest} />;
}

export default FormField(Select);

import { FormControlLabel, Grid, ListSubheader, MenuItem, Radio, Typography } from "@mui/material";
import ActivityDetail from "../ActivityDetail";
import React, { useState } from "react";
import { Yard } from "@mui/icons-material";
import TextField from "../common/TextField";
import Select from "../common/Select";
import RadioGroup from "../common/RadioGroup";

export default function PlantingCash({ activity }) {
    // menuitem style
    const mi = {
        paddingLeft: "32px"
    };

    // subheader style
    const sh = {
        fontWeight: "bold",
        fontSize: "1.15em",
        lineHeight: 2
    };

    const plantingOperations = [
        { type: "Planter", id: 1, name: "Strip till" },
        { type: "Planter", id: 2, name: "Strip till, subsoiler" },
        { type: "Planter", id: 3, name: "Double disk opener, 15 inch row spacing" },
        { type: "Planter", id: 4, name: "Relay or interseed" },
        { type: "Planter", id: 5, name: "Ridge till" },
        { type: "Planter", id: 6, name: "Bedder, hipper, hiller, 6 inch beds" },
        { type: "Seeding", id: 7, name: "Aerial, broadcast" },
        { type: "Seeding", id: 8, name: "Aerial, relay or interseed" },
        { type: "Seeding", id: 9, name: "Ground, broadcast" },
        { type: "Drill or air seeder", id: 10, name: "Double disk opener, fert openers" },
        { type: "Drill or air seeder", id: 11, name: "Hoe opener in heavy residue, fert openers" },
        { type: "Drill or air seeder", id: 12, name: "Double disk" }
    ];

    const [values, setValues] = useState({ operation: 10 });
    const handleSubmit = data => {
        setValues(data);
    };

    return (
        <ActivityDetail
            values={values}
            icon={<Yard />}
            type="Planting - Cash Crop"
            summary={
                <>
                    <Grid item sm={4} xs={12}>
                        <Typography style={{ fontSize: "14px" }}>EQUIPMENT / OPERATION TYPE</Typography>
                        <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
                            {
                                plantingOperations.find(obj => {
                                    return obj.id == values.operation;
                                }).name
                            }
                        </Typography>
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <Typography style={{ fontSize: "14px" }}>SEEDING RATE</Typography>
                        <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
                            {values.seeding_rate} seeds/ac
                        </Typography>
                    </Grid>
                </>
            }
            onSubmit={handleSubmit}>
            <Grid container paddingTop={2} spacing={1}>
                <Grid item xs={5}>
                    <Typography>Activity Date</Typography>
                    <TextField field="date" type="date" size="small" variant="outlined" fullWidth />
                </Grid>
                <Grid item sm={8} xs={12}>
                    <Typography>Operation</Typography>
                    <Select size="small" fullWidth field="operation" sx={{ width: "325px" }}>
                        <ListSubheader style={sh}>Planter</ListSubheader>
                        <MenuItem style={mi} value={1}>
                            Strip till
                        </MenuItem>
                        <MenuItem style={mi} value={2}>
                            Strip till, subsoiler
                        </MenuItem>
                        <MenuItem style={mi} value={3}>
                            Double disk opener, 15 inch row spacing
                        </MenuItem>
                        <MenuItem style={mi} value={4}>
                            Relay or interseed
                        </MenuItem>
                        <MenuItem style={mi} value={5}>
                            Ridge till
                        </MenuItem>
                        <MenuItem style={mi} value={6}>
                            Bedder, hipper, hiller, 6 inch beds
                        </MenuItem>
                        <ListSubheader style={sh}>Seeding</ListSubheader>
                        <MenuItem style={mi} value={7}>
                            Aerial, broadcast
                        </MenuItem>
                        <MenuItem style={mi} value={8}>
                            Aerial, relay or interseed
                        </MenuItem>
                        <MenuItem style={mi} value={9}>
                            Ground, broadcast
                        </MenuItem>
                        <ListSubheader style={sh}>Drill or air seeder</ListSubheader>
                        <MenuItem style={mi} value={10}>
                            Double disk opener, fert openers
                        </MenuItem>
                        <MenuItem style={mi} value={11}>
                            Hoe opener in heavy residue, fert openers
                        </MenuItem>
                        <MenuItem style={mi} value={12}>
                            Double disk
                        </MenuItem>
                    </Select>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <Typography>Seeding Rate</Typography>
                    <TextField
                        size="small"
                        type="number"
                        units="seeds/ac"
                        sx={{
                            "& input[type=number]::-webkit-outer-spin-button": {
                                "-webkit-appearance": "none",
                                margin: 0
                            },
                            "& input[type=number]::-webkit-inner-spin-button": {
                                "-webkit-appearance": "none",
                                margin: 0
                            }
                        }}
                        field="seeding_rate"
                        variant="outlined"
                    />
                </Grid>
                <Grid item sm={4} xs={6}>
                    <Typography>Seed Treatment</Typography>
                    <RadioGroup row field="seed_treatment">
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>
                <Grid item sm={4} xs={6}>
                    <Typography>Inoculant Applied</Typography>
                    <RadioGroup row field="inoculant_applied">
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>
            </Grid>
        </ActivityDetail>
    );
}

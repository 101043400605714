import React, { Component } from "react";
import { connect } from "react-redux";
import withRouter from "../common/withRouter";
import Grid from "../common/GridWrapper";
import withStyles from "@mui/styles/withStyles";
//import Facts from "./Facts";
import { Field as FieldModel, Farm } from "./models";
import { CropYear } from "./fieldactivities/models";
import AppContainer from "../common/AppContainer";
//import FarmHeader from "../dashboard/FarmHeader";
import { createSelector } from "../common/orm";
import * as navActions from "../common/actions";
import FarmFieldComponent from "../dashboard/FarmFieldComponent";

const allFarms = Farm.selectAll();

const getField = FieldModel.selectByUrlId(field => ({
    farm_name: field.farm.name,
    farm_id: field.farm.id,
    farm_obj: field.farm,
    ...field.ref
}));

const getFarmStats = createSelector(
    (state, ownProps) => ownProps.match.params["id"],
    (session, fieldId) => {

        let field = session.Field.filter({ id: fieldId }).toRefArray()[0];

        let farmStats = {};

        if (field !== undefined) {

            let fieldName = field.location === "(unknown)" ? "Curry County, NM" : field.location;

            let fields = session.Field.filter({ farm: field.farm }).toRefArray();

            let farmFields = fields.map(f => f.id);

            let filteredCY = session.CropYear.filter(cy => farmFields.includes(cy.field)).toRefArray().map(yr => parseInt(yr.year));
            let uniqueFilterdCY = [...new Set(filteredCY)];

            farmStats = session.Farm.filter({ id: field.farm }).toRefArray()[0];

            if (fieldName && fieldName.indexOf(",") > -1) {
                farmStats["County"] = fieldName.split(",")[0];
                farmStats["State"] = fieldName.split(",")[1].trim();
            }

            farmStats["FieldCt"] = fields.length;
            farmStats["FieldAcres"] = Math.round(fields.reduce((total, obj) => total + parseFloat(obj.size.split(" ")[0]), 0));
            farmStats["YearsEnrolled"] = uniqueFilterdCY;
            farmStats["YearsEnrolledLbl"] = uniqueFilterdCY.length > 1 ? Math.min(...uniqueFilterdCY) + " - " + Math.max(...uniqueFilterdCY) : uniqueFilterdCY[0] + "";

        }


        return farmStats;
    }
);

const getCropYears = createSelector(
    (state, ownProps) => ownProps.match.params["id"],
    (session, fieldId) => {
        return session.CropYear.filter({ field: fieldId })
            .orderBy("year", "desc")
            .toModelArray()
            .map(cropYear => ({
                crop_name: cropYear.crop.name,
                activitiesLoad: cropYear.activities.all().toModelArray(),
                _activities: cropYear.activities
                    .all()
                    .toModelArray()
                    .map(activity => {
                        const activityFert = activity.fertilizers.count();
                        return {
                            _fertilizers: activityFert > 0 && activity.fertilizers.all().toRefArray(),
                            ...activity.ref
                        };
                    }),
                ...cropYear.ref,
                fieldObject: cropYear.field._fields
            }));
    }
);

const styles = theme => ({
    marginTop: {
        marginTop: -36,
        marginLeft: -18,
        float: "left"
    },
    iconText: {
        height: 18,
        width: 24,
        marginBottom: -3
    },
    delBtn: {
        marginRight: -30
    },
    moveBtn: {
        marginRight: 16
    },
    cancelBtn: {
        border: "1px solid",
        marginRight: 24
    },
    rightFloat: {
        float: "right !important"
    },
    alertNotice: {
        textAlign: "center",
        backgroundColor: "#FFFFE0",
        padding: 8,
        fontSize: "1rem",
        lineHeight: "1.5em"
    },
    linkColor: {
        color: theme.palette.primary.main
    },
    titleContainer: {
        backgroundColor: "#ff7d32",
        textAlign: "center"
    },
    closeIcon: {
        float: "right",
        color: "#fff",
        marginTop: -16,
        marginRight: -16
    },
    dialogMin: {
        minWidth: "400px"
    },
    questionTitle: {
        color: "#fff",
        fontWeight: 500,
        float: "left"
    },
    button: {
        float: "right",
        marginTop: 24
    },
    marginTopTwo: {
        marginTop: 24
    },
    expansionPanel: {
        boxShadow: "none"
    },
    expansionSummary: {
        backgroundColor: "#F7F6F6",
        border: "1px solid #b9b9b9"
    },
    farmPannel: {
        backgroundColor: "#ffffff"

    },
    farmPannelText: {
        color: "#000000",
        "& span": {
            verticalAlign: "top"
        }
    },
    listItem: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(2),

    },
    listItemField: {
        width: "100%"
    },
    overflow: {
        overflowY: "hidden",
        overflowX: "hidden"
    },
    iconSize: {
        flexShrink: 0,
        width: "1em",
        height: "1em",
        fontSize: "20px",
        marginLeft: "5px"
    },
    farmIconSize: {
        flexShrink: 0,
        height: "50px",
        marginLeft: "10px",
        marginTop: "25px"
    },
    cropYearButtonPrimary: {
        backgroundColor: "#6d8d22",
        border: "solid 1px #4d8464",
        fontSize: "12px",
        color: "#fff",
        '&:hover': {
            backgroundColor: "#9eb95d"
        }
    },
    cropYearButtonSecondary: {
        backgroundColor: "#fff",
        border: "solid 1px #4d8464",
        fontSize: "12px",
        color: "#6d8d22",
        '&:hover': {
            backgroundColor: "#9eb95d"
        }
    },
    cropYearButtonSecondaryDisabled: {
        backgroundColor: "#fff",
        border: "solid 1px #4d8464",
        fontSize: "12px",
        color: "rgba(0, 0, 0, 0.26)"
    },
    fieldLink: {
        color: "#6d8d22"
    }
});

class Field extends Component {
    state = {
        deleteDialogOpen: false,
        deleteDialogText: null,
        deleteDialogConfirmAction: null,
        moveDialogOpen: false,
        moveDialogConfirmAction: null
    };

    deleteField = (field, name) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: field,
            deleteDialogText: "Are you sure you wish to permanently delete " + name + "?"
        });
    };

    moveField = field => {
        this.setState({
            moveDialogOpen: true,
            moveDialogConfirmAction: field
        });
    };

    doDeleteField = field => {
        this.props.ormFieldDelete(field);
        this.setState({ deleteDialogOpen: false });
        this.props.navSelectPage("dashboard");
        this.props.history.push("/dashboard");
    };

    doMoveField = values => {
        const { field, ormFieldUpdate } = this.props;
        ormFieldUpdate({
            id: field.id,
            farm: values.farm
        });
        this.setState({ moveDialogOpen: false });
    };

    errorValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        return {
            farm: isRequired(values.farm)
        };
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { classes, field, farmStats, cropYears, authState, navState, syncState } = this.props;

        return (
            <AppContainer
                authenticated
            // header={

            //     <FarmHeader
            //         title={(farmStats.name || "New Farm")}
            //         farmStats={farmStats}
            //     />

            // }
            >
                <Grid container spacing={24}>
                    <FarmFieldComponent key={"Field" + field.id} field={field} farmStats={farmStats} cropYears={cropYears} classes={classes} authState={authState} navState={navState} syncState={syncState} />
                </Grid>
            </AppContainer>
        );
    }
}

Field = connect(
    (state, ownProps) => ({
        field: getField(state, ownProps),
        farms: allFarms(state, ownProps),
        cropYears: getCropYears(state, ownProps),
        farmStats: getFarmStats(state, ownProps),
        navState: state.nav,
        authState: state.auth,
        syncState: state.sync
    }),
    {
        ...CropYear.actions,
        ...FieldModel.actions,
        ...navActions
    }
)(Field);

export default withStyles(styles)(withRouter(Field));

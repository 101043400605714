import { useState } from "react";
import ActivityDetail from "../ActivityDetail.jsx";
import { Agriculture, Help } from "@mui/icons-material";
import { Box, Divider, Grid, Link, MenuItem, Tooltip, Typography } from "@mui/material";
import TextField from "../common/TextField.jsx";
import Select from "../common/Select.jsx";
import Autocomplete from "../common/Autocomplete.jsx";
import TillageOperations from "./tillage_operations_1-9-25.json";

export default function Tillage({ activity }) {
    const [values, setValues] = useState({ operation: "" });
    const handleSubmit = data => {
        console.log(data);
        setValues(data);
    };

    TillageOperations.sort((a, b) => a.name.localeCompare(b.name));

    const [operationDescription, setOperationDescription] = useState(
        "Tillage operation, shallow, fluted coulters on 2 or more offset gangs on 10 inch spacing  followed by spike harrow, turbo-till or vertical-till concept.  Vegetation is killed."
    );
    const [operationStir, setOperationStir] = useState(18.53);
    const handleOperation = (event, selectedOperation) => {
        const operationObject = filteredOperations.find(obj => {
            return obj.name == selectedOperation;
        });
        if (operationObject) {
            setOperationDescription(operationObject.description);
            setOperationStir(operationObject.stir);
        }
    };

    const [currentFilter, setCurrentFilter] = useState("Chisel / Plow");
    const [filteredOperations, setFilteredOperations] = useState(TillageOperations);
    const handleFilter = filter => {
        setFilteredOperations(TillageOperations.filter(operation => operation.category === filter.target.value));

        setOperationDescription("");
        setOperationStir("");
        setCurrentFilter(filter.target.value);
    };

    return (
        <ActivityDetail
            values={values}
            icon={<Agriculture />}
            type="Tillage"
            summary={
                <>
                    <Grid item sm={4} xs={12}>
                        <Typography style={{ fontSize: "14px" }}>EQUIPMENT / OPERATION TYPE</Typography>
                        <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>{values.operation}</Typography>
                    </Grid>
                </>
            }
            onSubmit={handleSubmit}>
            <Grid container paddingTop={2} spacing={1}>
                <Grid item md={12} sm={12} xs={12}>
                    <Typography>Activity Date</Typography>
                    <TextField
                        field="date"
                        type="date"
                        size="small"
                        sx={{ width: "180px" }}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Typography>Filter Tillage Operations on Categories</Typography>
                    <Select
                        size="small"
                        fullWidth
                        value={currentFilter}
                        sx={{ width: "165px" }}
                        onChange={handleFilter}>
                        <MenuItem value={"Chisel / Plow"}>Chisel / Plow</MenuItem>
                        <MenuItem value={"Cultivator / Disk"}>Cultivator / Disk</MenuItem>
                        <MenuItem value={"Cultivator / Row"}>Cultivator / Row</MenuItem>
                        <MenuItem value={"Seedbed"}>Seedbed</MenuItem>
                        <MenuItem value={"Surface / Shallow"}>Surface / Shallow</MenuItem>
                    </Select>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Typography>Tillage Operation</Typography>
                    <Autocomplete
                        field="operation"
                        type="number"
                        size="small"
                        fullWidth
                        sx={{ width: "395px" }}
                        onChange={handleOperation}
                        options={filteredOperations.map(operation => operation.name)}
                        renderInput={params => <TextField {...params}></TextField>}></Autocomplete>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <Divider variant="middle" component="li" sx={{ listStyleType: "none" }} />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <Typography>Operation Description</Typography>
                    <Box sx={{ backgroundColor: "grey.300", borderRadius: 1, padding: 1 }}>
                        <Typography>{operationDescription}</Typography>
                    </Box>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                    <Typography>
                        Still Tillage Intensity Rating{" "}
                        <Tooltip
                            sx={{ verticalAlign: "middle" }}
                            title={
                                <Typography sx={{ color: "white", fontSize: "14px" }}>
                                    Soil Tillage Intensity Rating (STIR) Soil Tillage Intensity Rating is a numerical
                                    value calculated using RUSLE2. It is based on factors determined by crop management
                                    decisions being implemented for a particular field. Lower numbers indicate less
                                    overall disturbance to the soil layer.{" "}
                                    <Link
                                        target="_blank"
                                        href="https://www.nrcs.usda.gov/sites/default/files/2023-01/Soil-Tillage-Intensity-Rating-Fact-Sheet3-27-2020.pdf">
                                        https://www.nrcs.usda.gov/sites/default/files/2023-01/Soil-Tillage-Intensity-Rating-Fact-Sheet3-27-2020.pdf
                                    </Link>
                                </Typography>
                            }>
                            <Help fontSize="small" />
                        </Tooltip>
                    </Typography>
                    <Box sx={{ backgroundColor: "grey.300", borderRadius: 1, width: "50px", padding: 1 }}>
                        <Typography>{operationStir}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </ActivityDetail>
    );
}

import { InputAdornment, TextField as MuiTextField } from "@mui/material";
import { FormField } from "react-form";

function TextField({ fieldApi, units, sx, ...rest }) {
    const { setValue, getValue } = fieldApi;

    const InputProps = units ? { endAdornment: <InputAdornment position="end">{units}</InputAdornment> } : {};

    const removeNumberInputArrows = {
        "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
        },
        "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
        }
    };

    return (
        <MuiTextField
            InputProps={InputProps}
            value={getValue()}
            onChange={e => setValue(e.target.value)}
            sx={[removeNumberInputArrows, sx]}
            {...rest}
        />
    );
}

export default FormField(TextField);

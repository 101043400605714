import { Autocomplete as MuiAutocomplete } from "@mui/material";
import { FormField } from "react-form";

function Autocomplete({ fieldApi, onChange, ...rest }) {
    const { getValue, setValue } = fieldApi;
    const handleChange = (event, value) => {
        if (onChange) {
            onChange(event, value);
        }
        setValue(event.target.textContent);
    };
    return <MuiAutocomplete value={getValue()} onChange={handleChange} {...rest} />;
}

export default FormField(Autocomplete);

import React, { useState, createContext, useEffect } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Collapse,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemSecondaryAction,
    Typography
} from "@mui/material";

import covertermination from "./types/CoverTermination.jsx";
import cropprotectantspraying from "./types/CropProtectantSpraying.jsx";
import harvest from "./types/Harvest.jsx";
import nutrientcommercial from "./types/NutrientCommercial.jsx";
import nutrientgypsum from "./types/NutrientGypsum.jsx";
import nutrientlime from "./types/NutrientLime.jsx";
import nutrientmanure from "./types/NutrientManure.jsx";
import nutrientother from "./types/NutrientOther.jsx";
import plantingcash from "./types/PlantingCash.jsx";
import plantingcover from "./types/PlantingCover.jsx";
import residuemanagement from "./types/ResidueManagement.jsx";
import tillage from "./types/Tillage.jsx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import ActionButton from "./common/ActionButton.jsx";
import { ExpandMore, NavigateNext } from "@mui/icons-material";

export const ActivityContext = createContext({});

export default function ActivityList({ data, children }) {
    const [isActivityExpanded, setIsActivityExpanded] = useState(false);

    const [activities, setActivities] = useState([]);

    const addActivity = type => {
        setIsActivityExpanded(true);
        setActivities([...activities, { type: type, id: activities.length + 1 }]);
    };

    const removeActivity = id => {
        setActivities(activities.filter(activity => activity.id != id));
    };

    const expandActivity = e => {
        setIsActivityExpanded(true);
    };

    const collapseActivity = e => {
        setIsActivityExpanded(false);
    };

    function parseDate(dateString) {
        if (!dateString) {
            return null;
        }
        const parts = dateString.split("/");
        if (parts.length !== 3) {
            return null;
        }
        const [month, day, year] = parts;
        const parsedDate = new Date(year, month - 1, day);

        if (isNaN(parsedDate.getTime())) {
            return null;
        }

        return parsedDate;
    }

    useEffect(() => {
        let ct = activities.length;

        const newActivities = data.map(item => {
            ct = ct + 1;
            if (item.date) {
                return {
                    ...item,
                    date: new Date(item.date).toISOString(),
                    id: ct
                };
            } else {
                return {
                    ...item,
                    id: ct
                };
            }
        });

        setActivities([...activities, ...newActivities]);
    }, []);

    return (
        <Box sx={{ width: "100%", marginTop: "20px" }}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="fieldprint-activities"
                    id="fieldprint-activities"
                    style={{ width: "100%" }}>
                    <Typography style={{ fontSize: "16px", fontWeight: "bold", marginTop: "12px" }}>
                        Activities
                    </Typography>
                    <Box sx={{ display: "flex", gap: 1, marginLeft: "20px" }}>
                        <ActionButton
                            title="ADD NEW"
                            variant="contained"
                            style={{
                                backgroundColor: "#5388d8",
                                color: "#ffffff",
                                marginRight: "15px",
                                marginTop: "5px"
                            }}
                            startIcon={<AddCircleOutlineIcon />}
                            autoClose
                            actions={[
                                { name: "Planting - Cash Crop", onClick: e => addActivity("plantingcash", e) },
                                { name: "Planting - Cover Crop", onClick: e => addActivity("plantingcover", e) },
                                { name: "Tillage", onClick: e => addActivity("tillage", e) },
                                {
                                    name: "Crop Protectant / Spraying",
                                    onClick: e => addActivity("cropprotectantspraying", e)
                                },
                                {
                                    name: "Nutrient Application - Commercial",
                                    onClick: e => addActivity("nutrientcommercial", e)
                                },
                                {
                                    name: "Nutrient Application - Manure",
                                    onClick: e => addActivity("nutrientmanure", e)
                                },
                                { name: "Nutrient Application - Lime", onClick: e => addActivity("nutrientlime", e) },
                                {
                                    name: "Nutrient Application - Gypsum",
                                    onClick: e => addActivity("nutrientgypsum", e)
                                },
                                {
                                    name: "Nutrient Application - Other Organic Amendments",
                                    onClick: e => addActivity("nutrientother", e)
                                },
                                {
                                    name: "Residue Management",
                                    onClick: e => addActivity("residuemanagement", e)
                                },
                                { name: "Cover Crop Termination", onClick: e => addActivity("covertermination", e) },
                                { name: "Harvest", onClick: e => addActivity("harvest", e) }
                            ]}
                        />
                    </Box>
                    <Box sx={{ marginLeft: "auto", marginRight: "5px", paddingLeft: "5px" }}>
                        <Typography style={{ fontSize: "12px", color: "#afafaf" }}>
                            Soil Tillage Intensity Rating
                        </Typography>
                        <Typography style={{ fontSize: "14px", color: "#545454" }}>85 | Conventional</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ border: "none", borderTop: "solid 1px #e4e5e6", boxShadow: "none" }}>
                    <List>
                        {activities.map(activity => (
                            <ActivityContext.Provider
                                key={activity.id}
                                value={{ activity, removeActivity: () => removeActivity(activity.id) }}>
                                <Activity type={activity.type} activity={activity} />
                            </ActivityContext.Provider>
                        ))}
                        {activities.length === 0 ? (
                            <ListItem>
                                <Typography>There are currently no activities!</Typography>
                            </ListItem>
                        ) : (
                            <></>
                        )}
                    </List>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

const activityTypes = {
    plantingcash,
    plantingcover,
    tillage,
    cropprotectantspraying,
    nutrientcommercial,
    nutrientmanure,
    nutrientlime,
    nutrientgypsum,
    nutrientother,
    residuemanagement,
    covertermination,
    harvest
};

export function Activity({ type, activity }) {
    const Component = activityTypes[type];
    return <Component activity={activity} />;
}
